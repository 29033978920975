import React, { useCallback, useContext, useEffect } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { useDispatch, useSelector } from "react-redux";
import { toggleSideMenu } from "../../store/appActions";
import { getPrograms, runProgram } from "./programActions";
import { SocketContext } from "../../context/ConnectionProvider";
import { Paths } from "../../router/routes";
import { useTranslation } from "react-i18next";
import ButtonFactory from "../../coomponents/buttons";

const ProgramPage = () => {
  const params = useParams();
  const group = useSelector(({ programs }) =>
    programs.interface.find((group) => group._id === params.groupID),
  );
  
  if (!group) return null;

  return (
    <React.Fragment>
      <Box mb={2}>
        <Typography variant={"h4"} color={"primary"} align={"center"}>
          {group.name}
        </Typography>
      </Box>

      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {group.programs.map((program) => (
          <Grid item key={program._id} xs={6} ms={6} md={3} lg={2} xl={2}>
            <ButtonFactory
              {...program}
              groupID={group._id}
              active={group.activeProgram === program._id}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};

export { ProgramPage };
