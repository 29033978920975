import {AUTH, LOGOUT, TOGGLE_AUTO_MODE, TOGGLE_SIDE_MENU} from "./appActions";

const initState = {
    isAuth: false,
    token:"",
    latitude:0,
    longitude:0,
    timezone:"",
    projectUID:"",
    openMenu: false,
    isAutoMode: false,
    accessLevel:0
}

export const appReducer = (state = initState, action) => {
    const {type, payload} = action;
    switch (type) {
        case AUTH: {
            return {
                ...state,
                isAuth: true,
                token: payload.accessToken,
                projectUID:payload.projectUID,
                latitude:payload.latitude,
                longitude:payload.longitude,
                timezone:payload.timezone,
                accessLevel:payload.accessLevel
            }
        }
        case LOGOUT: {
            return {
                ...initState
            }
        }
        case TOGGLE_SIDE_MENU: {
            return {
                ...state,
                openMenu: !state.openMenu
            }
        }
        case TOGGLE_AUTO_MODE: {
            return {
                ...state,
                isAutoMode: payload
            }
        }
        default: {
            return {
                ...state
            }
        }
    }
}
