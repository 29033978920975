import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { useSelector } from "react-redux";

import AuthPage from "../pages/Auth/AuthPage";
import { ProgramsPage } from "../pages/Program/ProgramsPage";
import { ProgramPage } from "../pages/Program/ProgramPage";
import Calendar from "../pages/Calendar/Calendar";
import { MainView } from "../coomponents/MainView";
import { CalendarConnectionProvider } from "../context/CalendarConnectionProvider";
import { MainScreen } from "../pages/Program/MainScreen";
import { CloudCommandsProvider } from "../context/CloudCommandsProvider";

export const Paths = {
  Auth: "/auth",
  Programs: "/programs",
  Program: "/programs/:groupID",
  Calendar: "/calendar",
};

const Private = ({ path, exact, isAuth, children }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={() =>
        isAuth ? (
          <CloudCommandsProvider>
            <MainView>{children}</MainView>
          </CloudCommandsProvider>
        ) : (
          <Redirect to={Paths.Auth} />
        )
      }
    />
  );
};

const Guest = ({ path, exact, isAuth }) => {
  return (
    <Route
      path={path}
      exact={exact}
      render={() => (isAuth ? <Redirect to={Paths.Programs} /> : <AuthPage />)}
    />
  );
};

export const Routes = () => {
  const isAuth = useSelector(({ app }) => app.isAuth);
  return (
    <Switch>
      <Guest exact={true} path={Paths.Auth} isAuth={isAuth} />
      <Private exact={true} path={Paths.Programs} isAuth={isAuth}>
        <MainScreen />
      </Private>
      <Private exact={true} path={Paths.Program} isAuth={isAuth}>
        <ProgramPage />
      </Private>
      <Private exact={true} path={Paths.Calendar} isAuth={isAuth}>
        <CalendarConnectionProvider>
          <Calendar />
        </CalendarConnectionProvider>
      </Private>
    </Switch>
  );
};
