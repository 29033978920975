import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import { getPrograms } from "./programActions";
import { useSnackbar } from "notistack";
import { SocketContext } from "../../context/ConnectionProvider";
import ButtonFactory from "../../coomponents/buttons";

const buttonsSelector = ({ programs }) => {
  return programs.interface.find((group) => group.main) || {};
};

const MainScreen = () => {
  const dispatch = useDispatch();
  const mainGroup = useSelector(buttonsSelector);
  const projectUID = useSelector(({ app }) => app.projectUID);
  const connection = useContext(SocketContext);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (connection.socket)
      connection.socket.emit(
        "getPrograms",
        { projectUID },
        ({ status, message, data }) => {
          status === "success"
            ? dispatch(getPrograms(data))
            : enqueueSnackbar(message, { variant: status });
        },
      );
  }, [connection.socket]);

  if (!mainGroup) return null;
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      {mainGroup.programs
        ? mainGroup.programs.map((program) => (
            <Grid item key={program._id} xs={12} ms={12} md={3} lg={2} xl={2}>
              <ButtonFactory
                {...program}
                groupID={mainGroup._id}
                active={mainGroup.activeProgram === program._id}
              />
            </Grid>
          ))
        : null}
    </Grid>
  );
};

export { MainScreen };
