import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import reportWebVitals from "./reportWebVitals";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./theme/theme";
import { MemoryRouter } from "react-router-dom";
import { Paths } from "./router/routes";
import { rootReducer } from "./store/reducer";
import "moment/locale/uk";
import "moment/locale/en-gb";
import * as sw from "./serviceWorkerRegistration";
import "./18n";
import { LoadingScreen } from "./coomponents/LoadingScreen";

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const app = (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Suspense fallback={<LoadingScreen />}>
        <MemoryRouter initialEntries={Object.values(Paths)} initialIndex={0}>
          <App />
        </MemoryRouter>
      </Suspense>
    </ThemeProvider>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// sw.register({
//     onUpdate: (sw) => {
//         sw && sw.waiting.postMessage({type: 'SKIP_WAITING'})
//         alert("ПО обновлено. Пожалуйста перезапустите приложение.")
//     }
// })

sw.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
process.env.NODE_ENV === "development" && reportWebVitals(console.log);
