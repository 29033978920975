import React, { useEffect } from "react";
import { createContext, useCallback, useContext } from "react";
import { SocketContext } from "./ConnectionProvider";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { changeActiveProgramAction } from "../pages/Program/programActions";
import { Button } from "@material-ui/core";
import { toggleSideMenu } from "../store/appActions";
const CloudCommandsContext = createContext({
  runProgram: (groupID, programID) => {},
});

export const CloudCommandsProvider = ({ children }) => {
  const { socket: connection } = useContext(SocketContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const projectUID = useSelector(({ app }) => app.projectUID);
  const isAutoMode = useSelector(({ app }) => app.isAutoMode);

  const snackbarAction = useCallback(
    (key) => (
      <Button
        onClick={() => {
          dispatch(toggleSideMenu());
          closeSnackbar(key);
        }}
      >
        {t("message.on")}
      </Button>
    ),
    [],
  );

  const runProgram = useCallback(
    async (groupID, programID) => {
      if (!isAutoMode) {
        enqueueSnackbar(t("message.manualMode"), {
          variant: "warning",
          action: snackbarAction,
        });
        return;
      }
      if (!connection) return;
      
      const response = await connection.emitWithAck("runProgram", {
        projectUID,
        groupID,
        programID,
      });
      let message = response.message;
      if (message === "Программа запущена успешно.") {
        message = t("message.successRun");
      }
      if (message === "connected") {
        message = t("message.connected");
      }
      if (message === "await") {
        message = t("message.wait");
      }
      if (message.replace("не подключён", t("notConn")))
        enqueueSnackbar(message, { variant: response.status });
    },
    [connection, projectUID, isAutoMode],
  );

  const changeActiveProgram = useCallback(
    (groupID, programID) => {
      dispatch(changeActiveProgramAction({ groupID, programID }));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!connection) return;
    connection.on("activeProgramChange", changeActiveProgram);
    return () => {
      connection.off("activeProgramChange", changeActiveProgram);
    };
  }, [changeActiveProgram, connection]);

  return (
    <CloudCommandsContext.Provider value={{ runProgram }}>
      {children}
    </CloudCommandsContext.Provider>
  );
};

export const useCloudCommands = () => useContext(CloudCommandsContext);
