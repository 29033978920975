import React from "react";
import {PROGRAM_TYPES} from '../../constant';
import {LinkBtn} from './LinkBtn';
import {Command} from './Command';

const ButtonsMap = {
  [PROGRAM_TYPES.LINK]:LinkBtn,
  [PROGRAM_TYPES.COMMAND]:Command
}

const ButtonFactory = ({type,...props})=>{
  const Component = ButtonsMap[type];
  if(!Component) return null;
  return <Component type={type} {...props} />;
}

export default ButtonFactory
