import styled from "@emotion/styled";
import { Button } from "@material-ui/core";

export const BaseStyleButton = styled(Button,{
  shouldForwardProp: (prop) =>
    !["$color", "$borderColor", "$active"].includes(prop),
})`
  height: 7vh;
  min-height: 60px;
  background-color: ${({ $color }) => $color};
  border: ${({ $borderColor }) => `2px solid ${$borderColor}`};
  box-shadow: ${({ $active, $borderColor }) =>
    $active ? ` 0px 0px 15px 3px ${$borderColor}` : "none"};
`;
