import React from "react";
import { BaseStyleButton } from "./BaseStyleButton";
import { useCloudCommands } from "../../context/CloudCommandsProvider";

export const Command = ({ _id, groupID, name, borderColor, active, color }) => {
  const { runProgram } = useCloudCommands();
  return (
    <BaseStyleButton
      variant={"outlined"}
      fullWidth
      size="large"
      onClick={() => runProgram(groupID, _id)}
      $borderColor={borderColor}
      $active={active}
      $color={color}
    >
      {name}
    </BaseStyleButton>
  );
};
