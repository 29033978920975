export const GET_PROGRAMS = "GET_PROGRAMS";
export const RUN_PROGRAM = "RUN_PROGRAM";
export const CHANGE_ACTIVE_PROGRAM = "CHANGE_ACTIVE_PROGRAM";

export const getPrograms = (payload) => ({
  type: GET_PROGRAMS,
  payload,
});

export const changeActiveProgramAction = (payload) => ({
  type: CHANGE_ACTIVE_PROGRAM,
  payload,
});

export const runProgram = (groupName, programId) => ({
  type: RUN_PROGRAM,
  payload: { groupName, programId },
});
